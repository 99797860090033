var clientId = null;

function onRecaptchaLoadCallback() {
  clientId = grecaptcha.render("inline-badge", {
    sitekey: window.captchaSitekey,
    badge: "inline",
    size: "invisible",
  });
}
function loadReCaptcha() {
  if (window.captchaLoaded) {
    return;
  }

  var head = document.getElementsByTagName("head")[0];
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallback";
  head.appendChild(script);

  window.captchaLoaded = true;
}

$(function () {
  function handleCaptchaSubmit(event, self) {
    event.preventDefault();
    const id = self.attr("id");
    grecaptcha.ready(function () {
      grecaptcha.execute(clientId, { action: id }).then(function (token) {
        $("input.captcha-token").val(token);
        self.unbind("submit").submit();
      });
    });
  }

  $(".captchaForm").submit(function (event) {
    handleCaptchaSubmit(event, $(this));
  });

  $(".footerContactForm input").on("focus", function () {
    loadReCaptcha();
  });

  if (typeof captchaPage !== "undefined") {
    loadReCaptcha();
  }
});
